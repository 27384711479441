import gql from 'graphql-tag';

const DestinationFragment = gql`
  fragment DestinationFragment on Destination {
    __typename
    id
    crmDestination
    destID
    destinationName
    destinationType
    modified
    status
    uiID
    email
    agentID
    destination
    contentType
    body {
      key
      value
    }
    params {
      key
      value
    }
    headers {
      key
      value
    }
    method
    label
    attributesID
    testData
  }
`;
export default DestinationFragment;
