import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
  CardHeader,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Alert,
} from 'reactstrap';
import { LoadingButton } from '@/components';
import { FaFlask } from 'react-icons/fa';
import { useMutation } from '@apollo/react-hooks';
import { testDestinationMutation } from '@/graphql/mutations';
import { useNotifications } from '@/hooks';
import { NotificationActionsEnum } from '@/context/NotificationContext';

interface TestIntegrationModalProps {
  isOpen: boolean;
  toggle: () => void;
  destination: any;
  attributes: Array<{ field: string; mapTo: string }>;
  testData?: Record<string, string>;
}

const TestIntegrationModal: React.FC<TestIntegrationModalProps> = ({
  isOpen,
  toggle,
  destination,
  attributes,
  testData = {},
}) => {
  const { create: createNotification } = useNotifications();
  const [testDestination, { loading: testing }] = useMutation(
    testDestinationMutation,
    {
      onCompleted() {
        createNotification({
          message: 'Successfully sent test',
          type: 'alert',
          action: NotificationActionsEnum.message,
          color: 'success',
          timeout: 3000,
        });
      },
      onError(error) {
        createNotification({
          message: error.message,
          type: 'alert',
          action: NotificationActionsEnum.message,
          color: 'danger',
          timeout: 3000,
        });
      },
    }
  );

  // Initialize testValues with existing testData or empty strings
  const [testValues, setTestValues] = useState<Record<string, string>>(() => {
    const initialValues: Record<string, string> = {};

    attributes.forEach(({ mapTo }) => {
      initialValues[mapTo] = testData[mapTo] || '';
    });
    return initialValues;
  });

  useEffect(() => {
    if (isOpen) {
      const initialValues: Record<string, string> = {};
      attributes.forEach(({ mapTo }) => {
        initialValues[mapTo] = testData[mapTo] || '';
      });
      setTestValues(initialValues);
    }
  }, [isOpen, attributes, testData]);

  const handleInputChange = (mapTo: string, value: string) => {
    setTestValues((prev) => ({
      ...prev,
      [mapTo]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const testPayload: Record<string, string> = {};
    attributes.forEach(({ mapTo }) => {
      testPayload[mapTo] = testValues[mapTo] || '';
      if (mapTo.toLowerCase() === 'price') {
        testPayload[mapTo] = (Number(testValues[mapTo]) || 0).toString();
      }
    });

    testDestination({
      variables: {
        input: {
          destAddress: destination.destID,
          testData: JSON.stringify(testPayload),
        },
      },
    });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader wrapTag={CardHeader} toggle={toggle}>
        Test Integration
      </ModalHeader>
      <Form onSubmit={handleSubmit}>
        <ModalBody>
          <div className="mb-4">
            <h5>Integration Details:</h5>
            <div className="ml-3">
              <p>
                <strong>URL:</strong> {destination?.destination}
              </p>
              <p>
                <strong>Method:</strong> {destination?.method}
              </p>
              <p>
                <strong>Content Type:</strong> {destination?.contentType}
              </p>
            </div>
          </div>
          <Row noGutters className="justify-content-center">
            <Alert
              className="w-100"
              style={{ backgroundColor: 'rgb(68, 68, 68)' }}
            >
              Note: All attributes will be sent as strings during this test.
            </Alert>
          </Row>
          <div className="mb-3">
            <h5>Test Data:</h5>
            <div className="ml-3">
              {attributes.map(({ mapTo }) => (
                <FormGroup key={mapTo} className="mb-2">
                  <Row className="align-items-center">
                    <Col xs={2}>
                      <Label for={mapTo} className="mb-0">
                        {mapTo}:
                      </Label>
                    </Col>
                    <Col xs={9}>
                      <Input
                        type="text"
                        id={mapTo}
                        placeholder={`Enter ${mapTo}`}
                        value={testValues[mapTo] || ''}
                        onChange={(e) =>
                          handleInputChange(mapTo, e.target.value)
                        }
                      />
                    </Col>
                  </Row>
                </FormGroup>
              ))}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button color="secondary" onClick={toggle}>
              Close
            </Button>
            <LoadingButton color="info" type="submit" loading={testing}>
              Test Integration <FaFlask />
            </LoadingButton>
          </ButtonGroup>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default TestIntegrationModal;
