import gql from 'graphql-tag';

export default gql`
  fragment TransferFragment on Transfer {
    __typename
    id
    otherAgentFirstName
    otherAgentEmail
    otherAgentLastName
    diffAmount
    activityDate
    memo
  }
`;
