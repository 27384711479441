import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Container,
  FormText,
  Label,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  Form,
} from 'reactstrap';
import LoadingButton from '@gopro16/button';
import { FaLock } from 'react-icons/fa';
import MoneyInput from '@/components/MoneyInput';
import { useNotifications, useUser, useAccount } from '@/hooks';
import { useMutation } from '@apollo/react-hooks';
import { currentAgentQuery, currentAccountQuery } from '@/graphql/queries';
import { addCredit } from '@/graphql/mutations';
import { formatCurrencyForDisplay, Vars } from '@/utils';
import {
  calculateExpectedMatchValue,
  findPercentageForAgent,
  getDayName,
} from '@/utils/autoMatchDepositHelpers';

const AddCreditModal: React.SFC<{
  isOpen: boolean;
  toggle: () => void;
  broker?: any;
  corpID?: string;
}> = ({ isOpen, toggle, broker, corpID }) => {
  const { user } = useUser();
  const { account } = useAccount();
  let cardId = account?.card?.id;
  if (corpID && account) {
    if (account[`${corpID}Card`]) {
      cardId = account[`${corpID}Card`].id;
    }
  }
  const { create: createNotification } = useNotifications();
  const [creditToAdd, setCreditToAdd] = useState(0);
  const [confirmCreditToAdd, setConfirmCreditToAdd] = useState(false);
  const [expectedMatchValue, setExpectedMatchValue] = useState(0);

  console.log(broker);
  const networkBrokerID = user.network.brokerID;
  const selectedBroker = !broker
    ? { brokerID: user.network.brokerID, brokerName: user.network.networkName }
    : broker;
  const shouldShowAutoMatchAvailable =
    networkBrokerID === selectedBroker.brokerID &&
    user.network?.autoMatchDeposit?.enabled &&
    user.network?.autoMatchDeposit?.agentID !== user.agentID &&
    findPercentageForAgent(user.network?.autoMatchDeposit, user.agentID) > 0;

  console.log(selectedBroker);

  const handleCreditToAdd = (amount: number) => {
    setCreditToAdd(amount);
    setExpectedMatchValue(
      calculateExpectedMatchValue(
        amount,
        user.autoMatchDepositAvailable,
        user.agentID,
        user.network?.autoMatchDeposit
      )
    );
  };

  const [addMoreCredit, { loading: updating }] = useMutation(addCredit, {
    update(cache, { data: { accountAddCredit } }) {
      const { userCurrent } = cache.readQuery({
        query: currentAgentQuery,
      }) as any;

      const { account } = cache.readQuery({
        query: currentAccountQuery,
      }) as any;

      cache.writeQuery({
        query: currentAccountQuery,
        data: {
          account: {
            ...account,
            charges: [accountAddCredit.charge].concat(account.charges),
          },
        },
      });
      console.log(broker);
      console.log(corpID);
      console.log(accountAddCredit);

      cache.writeQuery({
        query: currentAgentQuery,
        data: {
          userCurrent: {
            ...userCurrent,
            totalCredit:
              !broker && !corpID
                ? accountAddCredit.credit
                : userCurrent.totalCredit,
            contributedCredit:
              !broker && !corpID
                ? (userCurrent.contributedCredit || 0) +
                  accountAddCredit.charge.amount
                : userCurrent.contributedCredit || 0,
            sonicCredit:
              broker.brokerID === Vars.sonicBrokerID && corpID
                ? accountAddCredit.credit
                : userCurrent.sonicCredit,
            otherBrokerCredits: broker
              ? userCurrent.otherBrokerCredits.map((b) => {
                  if (b.brokerID === broker.brokerID) {
                    return { ...b, credits: accountAddCredit.credit };
                  }
                  return b;
                })
              : userCurrent.otherBrokerCredits || [],
          },
        },
      });
    },
    onCompleted: () => {
      // createNotification({
      //   message: 'Added Credit To Account',
      //   type: 'alert',
      //   action: 'MESSAGE',
      //   color: 'success',
      // });
      setCreditToAdd(0);
      toggle();
    },
    onError: (error) =>
      createNotification({
        message: error.message.replace('GraphQL error:', ''),
        type: 'alert',
        action: 'MESSAGE',
        color: 'danger',
      }),
  });
  useEffect(() => {
    setConfirmCreditToAdd(false);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Add Credit to {selectedBroker.brokerName}
      </ModalHeader>
      <ModalBody>
        <Container>
          <Form
            onSubmit={(event: React.FormEvent<HTMLFormElement>): void => {
              event.preventDefault();

              addMoreCredit({
                variables: {
                  amount: creditToAdd,
                  cardId,
                  brokerID: selectedBroker.brokerID,
                  corpID,
                  networkID: user.networkID,
                },
              });
            }}
          >
            <Label for="addCredit">Add New Credit</Label>
            <FormText>{`Your current account credit is $${(
              (user?.totalCredit || 0) / 100
            ).toFixed(2)}`}</FormText>
            <InputGroup>
              <InputGroupAddon addonType="prepend">$</InputGroupAddon>
              <MoneyInput
                name="credit"
                onChange={handleCreditToAdd}
                value={creditToAdd}
                min={0}
              />
              <InputGroupAddon addonType="append">USD</InputGroupAddon>
            </InputGroup>
            <FormText>
              {/* this is enabled only for network broker level */}
              {shouldShowAutoMatchAvailable &&
                `Auto match deposit available $${(
                  (user?.autoMatchDepositAvailable || 0) / 100
                ).toFixed(2)}`}
              {shouldShowAutoMatchAvailable &&
                user?.autoMatchDepositAvailable > 0 &&
                `, expected match value: $${expectedMatchValue.toFixed(2)} `}
              <br />
              {shouldShowAutoMatchAvailable &&
                `Current week match ends on ${getDayName(
                  Number(user.network?.autoMatchDeposit?.endDay)
                )}. New match funds available on ${getDayName(
                  Number(user.network?.autoMatchDeposit?.startDay)
                )}.`}
            </FormText>
            <br />
            <span className="h4 text-warning">
              Your card will be charged: {formatCurrencyForDisplay(creditToAdd)}
            </span>
            <br />
            <FormGroup className="mt-3 d-flex flex-column">
              <div>
                <Button
                  color="success"
                  onClick={() => setConfirmCreditToAdd(true)}
                  disabled={creditToAdd === 0 || confirmCreditToAdd}
                >
                  <span className="mr-2">Add Credit</span>
                  <FaLock />
                </Button>
                {confirmCreditToAdd && (
                  <LoadingButton
                    className="ml-3"
                    color="warning"
                    loading={updating}
                    disabled={updating}
                  >
                    <span className="mr-2">Confirm</span>
                    <FaLock />
                  </LoadingButton>
                )}
              </div>
              <br />
              <small className="text-muted">
                Pressing this button charges your card. Please review the total
                amount above before pressing this button.
              </small>
            </FormGroup>
          </Form>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddCreditModal;
