export interface AutoMatchDeposit {
  enabled: boolean;
  agentID: string;
  minDepositAmount: number;
  matchPercentages: { role: string; percentage: number }[];
  groups: {
    name: string;
    agents: string[];
    percentage: number;
  }[];
  minTransferAmount: number;
  maxTransferAmount: number;
  startDay: number;
  endDay: number;
}

const isTodayInInterval = (startDay: number, endDay: number): boolean => {
  const today = new Date().getUTCDay();

  // Straightforward case: today should be between startDay and endDay inclusively
  if (startDay <= endDay) {
    return today >= startDay && today <= endDay;
  }

  // Wrap-around case: today is either >= startDay or <= endDay
  return today >= startDay || today <= endDay;
};

export const calculateExpectedMatchValue = (
  creditToAdd: number,
  autoMatchDepositAvailable: number,
  agentID: string,
  autoMatchDeposit?: AutoMatchDeposit
): number => {
  if (autoMatchDepositAvailable > 0 && autoMatchDeposit?.enabled) {
    const matchPercentage = findPercentageForAgent(autoMatchDeposit, agentID);

    if (!matchPercentage) {
      console.log('No match percentage found for user role');
      return 0;
    }

    if (creditToAdd < autoMatchDeposit.minDepositAmount) {
      console.log('Credit to add is less than the minimum deposit amount');
      return 0;
    }

    if (
      !isTodayInInterval(autoMatchDeposit.startDay, autoMatchDeposit.endDay)
    ) {
      console.log('Today is not in the interval');
      return 0;
    }

    let amountToTransfer = creditToAdd * (Number(matchPercentage) / 100);

    if (amountToTransfer < autoMatchDeposit.minTransferAmount) {
      console.log(
        'Amount to transfer is less than the minimum transfer amount'
      );
      return 0;
    }

    const currentConsumed =
      autoMatchDeposit.maxTransferAmount - autoMatchDepositAvailable;
    const remainingAmount =
      autoMatchDeposit.maxTransferAmount - currentConsumed;
    if (amountToTransfer > remainingAmount) {
      amountToTransfer = remainingAmount;
    }

    if (amountToTransfer <= 0) {
      console.log('No amount left to transfer within the interval limit.');
      return 0;
    }

    return amountToTransfer / 100;
  }

  return 0;
};

export const findPercentageForAgent = (
  autoMatchDeposit: AutoMatchDeposit,
  agentID: string
): number => {
  const agentGroup = autoMatchDeposit?.groups?.find((g) =>
    g.agents.some((id) => id === agentID)
  );
  return agentGroup?.percentage || 0;
};

export function getDayName(dayNumber: number): string {
  const days = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  return days[dayNumber];
}
