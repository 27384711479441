import {
  testDestinationMutation,
  updateDestination as updateDestinationMutation,
} from '@/graphql/mutations';
import { useMutation } from '@apollo/react-hooks';
import useDestinations from './useDestinations';
import useNotifications from './useNotifications';
import { NotificationActionsEnum } from '@/context/NotificationContext';

const useDestination = ({
  agentID,
  destinationID,
}: {
  agentID: string;
  destinationID?: string;
}) => {
  const { destinations, loading, error, refetch } = useDestinations(agentID);
  const { create: createNotification } = useNotifications();
  const destination = destinations.find((d) => d.destID === destinationID);

  const [updateDestination, { loading: updating }] = useMutation(
    updateDestinationMutation,
    {
      update() {
        if (destinationID !== 'custom') {
          createNotification({
            message: 'Successfully updated destination',
            type: 'alert',
            action: NotificationActionsEnum.message,
            color: 'success',
            timeout: 1000,
          });
        } else {
          createNotification({
            message: 'Successfully created destination',
            type: 'alert',
            action: NotificationActionsEnum.message,
            color: 'success',
            timeout: 1000,
          });
        }
        refetch({
          agentID,
        });
      },
    }
  );

  return {
    loading,
    error,
    updating,
    update: updateDestination,
    destination,
  };
};

export default useDestination;
