/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect } from 'react';
import { SignIn } from 'aws-amplify-react';
import { ConsoleLogger as Logger } from '@aws-amplify/core';
import { Auth } from 'aws-amplify';
import { Badge, Button, Form } from 'reactstrap';
import LoadingButton from '@gopro16/button';
import BlockUI from 'react-block-ui';
import { Formik } from 'formik';
import { Field } from '@availity/form';
import moment from 'moment';
import * as yup from 'yup';
import AuthHeader from './AuthHeader';

const isSonic = window.location.href.includes('sonicleads');
const isAmericaFirst = window.location.href.includes('americafirstleads');
const isLeadtopia = window.location.href.includes('leadtopia');

const firstTime = !localStorage.getItem('firstTimeLogin');

const logger = new Logger('SignIn');

// const SignIn = ({ onValidSubmit, onInvalidSubmit }) => (

const showHelpEmail = () => {
  if (isSonic) {
    return <a href="mailto:support@sonicleads.com">support@sonicleads.com</a>;
  }

  if (isAmericaFirst) {
    return (
      <a href="mailto:leadsupport@americafirstleads.com">
        leadsupport@americafirstleads.com
      </a>
    );
  }

  if (isLeadtopia) {
    return <a href="mailto:support@leadtopia.com">support@leadtopia.com</a>;
  }

  return (
    <a href="mailto:support@ushamarketplace.com">support@ushamarketplace.com</a>
  );
};

class CustomSignIn extends SignIn {
  public constructor(props: any) {
    super(props);
    this._validAuthStates = ['signIn'];
  }

  public async signIn(event: any) {
    // avoid submitting the form
    if (event) {
      event.preventDefault();
    }

    const username = this.getUsernameFromInput() || '';
    const { password } = this.inputs;

    if (!Auth || typeof Auth.signIn !== 'function') {
      throw new Error(
        'No Auth module found, please ensure @aws-amplify/auth is imported'
      );
    }
    this.setState({ loading: true });
    try {
      const user = await Auth.signIn({
        username,
        password,
        validationData: { domain: window.location.hostname },
      });
      logger.debug(user);
      if (
        user.challengeName === 'SMS_MFA' ||
        user.challengeName === 'SOFTWARE_TOKEN_MFA'
      ) {
        logger.debug(`confirm user with ${user.challengeName}`);
        this.changeState('confirmSignIn', user);
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        logger.debug('require new password', user.challengeParam);
        this.changeState('requireNewPassword', user);
      } else if (user.challengeName === 'MFA_SETUP') {
        logger.debug('TOTP setup', user.challengeParam);
        this.changeState('TOTPSetup', user);
      } else if (
        user.challengeName === 'CUSTOM_CHALLENGE' &&
        user.challengeParam &&
        user.challengeParam.trigger === 'true'
      ) {
        logger.debug('custom challenge', user.challengeParam);
        this.changeState('customConfirmSignIn', user);
      } else {
        this.checkContact(user);
      }
    } catch (error) {
      if (error.code === 'UserNotConfirmedException') {
        logger.debug('the user is not confirmed');
        this.changeState('confirmSignUp', { username });
      } else if (error.code === 'PasswordResetRequiredException') {
        logger.debug('the user requires a new password');
        this.changeState('forgotPassword', { username });
      } else {
        console.error(error);
        return error;
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  public showComponent(): JSX.Element {
    const cameFromSignUp = !!this.props.authData;

    const queryParams = new URLSearchParams(window.location.search);
    const forceSignUp = queryParams.get('forceSignUp') as string;
    if (forceSignUp === 'true') {
      this.changeState('signUp');
    }

    return (
      <AuthHeader cardTitle="Sign in to Your Account">
        <Formik
          onSubmit={async (values, { setFieldError }) => {
            if (!firstTime) {
              localStorage.setItem('firstTimeLogin', moment().format());
            }
            const error = await this.signIn(null);
            if (error) {
              setFieldError('username', 'Email and/or password are incorrect.');
              setFieldError('password', 'Email and/or password are incorrect.');
            }
          }}
          initialValues={{
            username: '',
            password: '',
          }}
          validationSchema={yup.object().shape({
            username: yup.string().required('Please enter an email.'),
            password: yup.string().required('Please enter a Password.'),
          })}
        >
          {({ isSubmitting, handleSubmit, handleReset }) => (
            <Form onSubmit={handleSubmit} onReset={handleReset}>
              <BlockUI blocking={isSubmitting}>
                {!isSonic && cameFromSignUp && (
                  <Badge
                    color="warning"
                    className="d-flex justify-content-center mb-2"
                    style={{ fontSize: 15 }}
                  >
                    Please sign into your account to complete your account
                    creation.
                  </Badge>
                )}
                {isSonic && firstTime && (
                  <p>
                    If this is your first time logging in, please check your
                    email for your login information
                  </p>
                )}
                <Field
                  id="username"
                  name="username"
                  type="email"
                  onBlur={(event) => {
                    const {
                      target: { value },
                    } = event;
                    let val = value.toLowerCase();

                    if (isSonic) {
                      val = event.target.value.replace('@', '+1@');
                    }
                    this.inputs.username = val;
                  }}
                  onChange={(event) => {
                    const {
                      target: { value },
                    } = event;
                    let val = value.toLowerCase();

                    if (isSonic) {
                      val = event.target.value.replace('@', '+1@');
                    }
                    this.inputs.username = val;
                  }}
                  placeholder="Email"
                  label="Email"
                />
                <Field
                  name="password"
                  type="password"
                  onChange={this.handleInputChange}
                  placeholder="Password"
                  label="Password"
                />
              </BlockUI>
              <LoadingButton
                loading={isSubmitting}
                disabled={isSubmitting}
                color="primary"
                size="lg"
                type="submit"
                block
              >
                Sign In
              </LoadingButton>
            </Form>
          )}
        </Formik>
        <div className="d-flex flex-column pt-2">
          <span>
            Forgot your password?{' '}
            <Button
              color="link"
              onClick={() => this.changeState('forgotPassword')}
            >
              Reset Password
            </Button>
          </span>
          <span>
            No Account?{' '}
            <Button color="link" onClick={() => this.changeState('signUp')}>
              Create Account
            </Button>
          </span>
          <span>
            <p>For help please email {showHelpEmail()}</p>
          </span>
        </div>
      </AuthHeader>
    );
  }
}

export default CustomSignIn;
